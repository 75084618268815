<template>
  <div class="login-cont">
    <section id="firebaseui-auth-container"></section>
  </div>
</template>

<script>
import firebase from "firebase/app"
import 'firebase/auth'
import * as firebaseui from "firebaseui"


import "firebaseui/dist/firebaseui.css"
import store from "@/store";

export default {
  name: "PhoneLogin",
  data() {
    return {};
  },
  mounted() {
    let ui = firebaseui.auth.AuthUI.getInstance();
    if (!ui) {
      ui = new firebaseui.auth.AuthUI(firebase.auth());
    }
    const uiConfig = {
      signInSuccessUrl: "/", // This redirect can be achieved by route using callback.
      callbacks: {
        // eslint-disable-next-line no-unused-vars
        signInSuccessWithAuthResult: function (currentUser, credential, redirectUrl) {
          window.location.assign(`/`);
          // Do not automatically redirect.
          return false;
        },
      },
      signInFlow: "popup",
      signInOptions: [
        {
          provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
          defaultCountry: 'RU',
        },
      ]
    };
    ui.start("#firebaseui-auth-container", uiConfig);
  }
};
</script>

<style>
.login-cont {
 background-image: url("https://www.litmus.com/wp-content/uploads/2020/04/ultimate-guide-to-background-images-in-email.png");
 background-color: #cccccc;
  width: 100%;
  height: 100%;
}
</style>
